/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import 'assets/layout/styles/layout/layout.scss';

/* PrimeNG */
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '@fortawesome/fontawesome-free/css/all.css';


@font-face {
    font-family: 'Roboto Condensed';
    src: url('assets/fonts/RobotoCondensed-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto Condensed Medium';
    src: url('assets/fonts/RobotoCondensed-Medium.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto Condensed Bold';
    src: url('assets/fonts/RobotoCondensed-Bold.ttf') format('truetype');
    font-weight: bold;
}


@font-face {
    font-family: 'Roboto Condensed Light';
    src: url('assets/fonts/RobotoCondensed-Light.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto Condensed Extra Light';
    src: url('assets/fonts/RobotoCondensed-ExtraLight.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto Condensed Thin';
    src: url('assets/fonts/RobotoCondensed-Thin.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto Condensed Black';
    src: url('assets/fonts/RobotoCondensed-Black.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto Black';
    src: url('assets/fonts/Roboto-Black.ttf') format('truetype');
    font-weight: normal;
}


@font-face {
    font-family: 'Avenir Next Condensed';
    src: url('assets/fonts/Avenir-Next-Condensed.ttc') format('truetype');
    font-weight: normal;
}

.custom-panel {
    width: 24rem;
    margin-bottom: 5px;
    margin-top: 5px;
}


.custom-green-icon .pi-circle-on {
    color: green;
}

.orange-icon {
    color: green;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.opop-inputtextrow {
    max-width: 100px;
}

.opop-textlabel {
    color: var(--blue-900);
    font-weight: bold;
    font-size: 12px;
    margin-top: 4px;
    margin-left: 2px;
    margin-bottom: 3px;
}

.opop-cardlabel {
    color: var(--orange-600);
    font-weight: bold;
    font-size: 14px;
    margin-top: 0px;
    margin-left: 2px;
    margin-bottom: 12px;
}

.opop-viewtext-head {
    color: var(--gray-600);
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size: 1.3rem;
    margin-top: 40px;
    margin-left: 2px;
    margin-bottom: 0px;
}

.opop-inputlabel {
    color: var(--gray-600);
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 600;
    font-size: 1.3rem;
    margin-top: 15px;
    margin-left: 2px;
    margin-bottom: 3px;
}

.opop-viewtext {
    color: var(--gray-800);
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 200;
    font-size: 1.3rem;
    margin-top: 5px;
    margin-left: 2px;
    margin-bottom: 3px;
}

.opop-inputlabel-edit {
    font-family: 'Roboto Condensed Light', sans-serif;
    color: var(--gray-900);
    font-size: 1.2rem;
    margin-top: 35px;
    margin-left: 2px;
    margin-bottom: 0;
}

.opop-inputtext {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.2rem;
    color: var(--gray-900);
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 2px;
    padding:0;
    border-radius: 0.3rem;
    border: 1 solid var(--gray-200);
    overflow-y: hidden;
}

.opop-inputtext-bold {
    font-family: 'Roboto Condensed Medium', sans-serif;
    font-size: 1.4rem;
    color: var(--gray-800);
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 2px;
    padding:0;
    width:100%;
    height:auto;
}

.opop-inputtext-normal {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.2rem;
    color: var(--gray-600);
    margin-top: 0;
    margin-bottom: 5px;
    margin-left: 2px;
    padding:0;
    width:100%; height:auto;
}

.opop-inputtext-autoresize {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
    border-radius: 0.3rem;
    border: 0;
    overflow-y: hidden;
}

.centered-col {
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.innerCentered-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    align-items: center;
}

.registerScreens-width {
    width: 60%; /* or whatever value you prefer */
}

@media only screen and (max-width: 575px) {
    .custom-margin {
        width: 80%;
        margin-left: 40px;
        margin-right:10px;
    }
}

.country-autoComplete .p-autocomplete-input {
    width: 100%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.single-row {
    display: flex;
    align-items: center;
}

.card {
    background: var(--surface-card);
    box-sizing: border-box;
    padding: 1rem;
    margin-top: 0;
    margin-bottom: 2rem;
    box-shadow: none;
    border-radius: 0.3rem;
    border: 1px solid var(--gray-200);
}

.card-home {
    background: transparent;
    box-sizing: border-box;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
}

.container {
    position: relative;
}


.op-chip-plan {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    background-color: #2170e7;
    color: white;
    border-radius: 13px;
    padding: 7px;
    padding-left: 16px;
    text-align: left;
    width: 160px;
    height: 30px;
    margin-bottom: 5px;
    border: 0.5px solid #808080;
}

.op-chip {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    background-color: #2170e7;
    color: white;
    border-radius: 13px;
    padding: 7px;
    padding-left: 16px;
    text-align: left;
    width: 170px;
    height: 30px;
    margin-bottom: 5px;
    border: 0.5px solid #808080;
}


.op-status {
    background-color: #2170e7;
    color: black;
    border-radius: 8px;
    padding: 7px;
    padding-left: 16px;
    text-align: justify;
    width: 110px;
    height: 100px;
    margin-bottom: 5px;
    border: 0.5px solid #808080;
}


.info-container {
    flex: 0 0 80%;
    padding-left: 10px; /* Adjust the padding as needed */
}

.company-name {
    font-weight: bold;
    margin-bottom: 10px; /* Adjust the margin as needed */
}

.contact-info {
    font-size: 14px; /* Adjust the font size as needed */
}

h7 {
    margin: 1.5rem 0 1rem 0;
    font-family: inherit;
    font-weight: 300;
    line-height: 1.5;
    color: var(--surface-900);
}

body .p-button {
    //height: 20px !important;
    //line-height: 30px !important; /* You may need to adjust this to get the text vertically centered */
    //padding: 1rem 1em !important; /* Adjust horizontal padding if necessary */
    border-radius: 0px;
}

.custom-select-button {

}

body .custom-select-button .p-button {
    height: 42px !important;
    width:400px;
    max-width: 100%;
    line-height: 44px !important;
    padding: 1rem 1em !important;
    border-radius: 0;
}


.custom-inputtext {
    border-radius: 0 0.25rem 0.25rem 0;
}

.relative {
    position: relative;
}

.image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.p-tag-icon, .p-tag-value, .p-tag-icon.pi {
    padding-top: 5px !important;
}

.p-tag-value {
    margin-bottom: 5px !important;
}


.op-div {
    border: 0.5px solid lightgray;
    border-radius: 5px;
    padding: 10px;
}


/* do ovde */
.p-tag-icon, .p-tag-value, .p-tag-icon.pi {
    padding-top: 5px !important;
}

.p-tag-value {
    margin-bottom: 5px !important;
}

.muni-table-head-inputgroup {
    display: flex;
    align-items: stretch;
    width: 100%
}

.card-muni {
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05);
    padding: 10px 2px 2px;
    box-sizing: border-box;
    margin-bottom: 0;
    width: 100%;
}

.p-timeline-event-opposite, .p-timeline-event-content {
    flex: unset !important;
}

.page-title {
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05);
    padding: 8px;
    box-sizing: border-box;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: grey !important;
}

.p-paginator-rpp-options {
    min-height: 30px !important;
}

.p-inplace .p-inplace-display {
    display: inline-block !important;
    width: 100%;
}

.card-author {
    border-radius: 8px;
    background: var(--surface-card);
    box-sizing: border-box;
    width: 100%;
}

.card-date-status {
    border-radius: 8px;
    background: var(--surface-card);
    box-sizing: border-box;
    width: 100%;
}



.buttonSetWidth {
    min-width: 120px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.captionWidth {
    min-width: 150px;
    width: 100%;
}


/* Works on Chrome, Edge, and Safari */

:root {
    --scroll-bar-color: #404c50;
    --text-scale-fact: 1.1;
}

*::-webkit-scrollbar {
    width: 8px;
    height: 12px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--scroll-bar-color) !important;
    border-radius: 20px;
    border: 3px solid var(--scroll-bar-bg-color);
}

.icon-hover {
    cursor: pointer;
}

#registerDonorType .p-buttonset .p-button:first-of-type {
    height: 70px;
}

#registerDonorType .p-button .p-button-label {
    height: 48px;
}

.p-panel .p-panel-header {
    background: var(--indigo-color);
    padding: 0.8rem;
}

.campaignImg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
}

.campaign-container {
    margin-top:50px;
    padding:50px;
    width: 100%;
    height: 600px; /* Adjust the height as needed */
}

.campaign-background-image {
    //position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.9;
}

.campaign-text-container {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.campaign-copy-container {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.selectedImage {
    border: 10px solid teal;
}


.opopUpload-noDocs .p-panel-header {
    background: var(--gray-50);
    color:black;
}
.opopUpload-docs .p-panel-header {
    background: teal;
    color:white;
}


.p-dropdown .p-dropdown-label {
    //padding: 7px !important;
}

//.p-autocomplete .p-autocomplete-dropdown {
//    border-radius: 0;
//    height: 30px !important;
//}

.noPanelPadding .p-panel-content {
    padding: 0 !important;
}

.opop-image-container {
    margin-top:-1px;
    margin-bottom:5px;
    max-height: 330px;
    overflow: hidden;
}

.opop-image-container img {
    width: 100%;
    height: auto;
}

.opop-field-height {
    height: 32px !important;
}

.opop-field-height-donate {
    height: 28px !important;
    flex-grow: 1;
    max-width: 180px;
    font-family: 'Roboto', sans-serif;
    color: var(--gray-800);
    font-size: 1rem;
    border-radius: 0 !important;
    border: 0px solid var(--gray-200);
    width: 60%;
    border-radius:0;
}


.company-image-container {
    width: 50%;
    max-height: 230px !important;
    overflow: hidden;
}

.company-image-container img {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.company-field-height {
    height: 32px !important;
}



/* CSS for the Project on Front Page */

.opop-image-container-fp  {
    position: relative;
    height: 330px;
    overflow: hidden;
}

.opop-image-container-fp img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
}

/* CSS for the logo container */
.opop-logo-container-background-fp {
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 70px;
    height: 90px;
    background: linear-gradient(to bottom, transparent 0%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.7) 100%);
    border-radius: 0%;
    display: flex;
}

.opop-logo-container-fp {
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 60px;
    height: 60px;
    border-radius: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* CSS for the logo image */
.opop-logo-container-fp img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
}

/* CSS for the short name container */
.opop-shortname-container-fp {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30px;
    width: calc(100% - 70px);
    background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.7) 100%);
    display: flex;
    justify-content: right;
    align-items: center;
    color: #0e0e0e;
}

/* CSS for the short name text */
.opop-shortname-container-fp span {
    margin-right:15px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: 1.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}



.op-title-fp {
    font-family: 'Roboto Condensed Medium', sans-serif;
    font-size:1.2rem;
    color:  var(--gray-600);
    padding: 0;
    text-align: left;
    margin-bottom: 6px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-stretch: normal;
}

.op-text-fp {
    font-family: 'Roboto Condensed', sans-serif;
    font-size:1.1rem;
    color:  var(--gray-600);
    padding: 0;
    text-align: left;
    margin-bottom: 6px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-stretch: normal;
}

/* CSS for the Short Description on Project of the Front Page */
.op-description-fp {
    flex-grow: 1;
    color: #0e0e0e;
    padding: 2px;
    text-align: left;
    width: 100%;
    margin-bottom: 2px;
    font-family: 'Roboto Condensed Light', sans-serif;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.p-2.mt-1:hover .op-text-fp,
.p-2.mt-1:hover .op-title-fp,
.p-2.mt-1:hover .opop-viewtext-full {
    color: var(--gray-900);
    transition: color 0.2s ease; /* Optional: Smooth transition for color change */
}


.mail-box {
    display: grid;
    margin: auto;
    margin-top:80px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    width: 90%;  max-width:700px;
    height: auto;
    padding: 40px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 0;
}

.back-box {
    display: grid;
    //grid-template-columns: repeat(2, 1fr); /* Two columns of equal width */
    //gap: 20px; /* Gap between fields */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    //width: 80%;  /* or whatever width you want */
    width: 800px; /* or whatever max width you want */
    height: 500px; /* or whatever max width you want */
    padding: 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 0;
}

.virman-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns of equal width */
    gap: 20px; /* Gap between fields */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    //width: 80%;  /* or whatever width you want */
    width: 800px; /* or whatever max width you want */
    height: 500px; /* or whatever max width you want */
    padding: 10px;
    background-color: var(--pink-100);
    border: 1px solid #ccc;
    border-radius: 0;
}

.field {
    color:green;
}

.back-box form .field:nth-child(-n+3) {
    grid-column: 1;
}

.back-box form .field:nth-child(n+4) {
    grid-column: 2;
}



.button-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:2px;
}

.success {
    color: green;
    border: 1px solid green;
    padding: 10px;
    margin: 10px 0;
    background-color: #e6ffe6;
}

.error {
    color: red;
    border: 1px solid red;
    padding: 10px;
    margin: 10px 0;
    background-color: #ffe6e6;
}

.center-div {
    display: flex;
    justify-content: center;
    //align-items: center;
    height: 50%; /* or any desired height */
}

.red-star-icon {
    color: gray;
    font-size: 8px; /* Adjust as per your need */
    margin-left: 5px;
    margin-top:13px;
}

.red-star-icon-red{
    color: gray;
    font-size: 12px; /* Adjust as per your need */
    margin-left: 5px;
    margin-top:10px;
}

.white-star-icon {
    color: white;
    font-size: 8px; /* Adjust as per your need */
    margin-left: 5px;
    margin-top:13px;
}

.personGroupContainer {
    position: relative;
    margin-left: 20px;
    width:80%;
}

.personGroupTitle {
    position: absolute;
    color:#707070;
    top: -10px; /* Adjust based on your preference */
    left: 20px; /* This will be the left margin for the title */
    background-color: #ffffff; /* This should be the background color of your page */
    padding: 0 10px; /* This is to ensure the text does not stick to the border */
}

.personGroupBox {
    border: 0.5px solid lightgray; /* This will be the border for the box */
    border-radius: 10px; /* This will round the corners of the box */
    padding: 20px; /* This adds some space inside the box */
    margin-top: 0px; /* This gives space for the title above the box */
    margin-bottom: 0px; /* This is the bottom margin for the box */
}

.terms-button-container {
    display: flex;
    justify-content: space-between; /* this will put maximum space between the left and center buttons */
    align-items: center; /* this will vertically center the buttons */
}

.terms-left-button {
    margin-left: 20px; /* this will ensure the download button is to the far left */
}

.terms-center-button{
    width:100%;
    display: flex;
    text-align: center;
}

.terms-center-buttons {
    display: flex;
    gap: 10px; /* this will add space between the Accept and Decline buttons */
}

.terms-right-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

hr {
     border-color: lightgrey;
     border-width: 1px;
 }

.no-margin-list, .no-margin-list ul {
    margin-left: 0 !important;
    padding-left: 20px !important;
}

.flex-button--container {
    display: flex;
    justify-content: space-between;
    align-items: center; /* This will center align items vertically */
    width: 100%;
}

.disable-pointer {
    pointer-events: none;
    opacity: 0.5; /* optional, to provide visual feedback of disabled state */
}

body {
    padding-bottom: 80px; /* Adjust based on the height of your footer */
}

.layout-topbar {
    //border:2px solid red;
    width: 100% !important;
    margin-left:0 !important;
    margin-right:0 !important;
    padding:0 !important;
}
//
//.topbar-start {
//    padding: 0 !important;
//    margin:0 !important;
//}

.topbar-menubutton {
    //border:2px solid green;
    padding: 0 !important;
    margin:0 !important;
}

.date-display {
    border: 1px solid #ccc;
    height: 32px;
    padding-right: 30px; /* Space for the icon */
    position: relative;
}
//
//.topbar-end {
//    padding:0;
//}
.top-bar-wrapper {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 1000;
    box-shadow: none;
}

.layout-topbar {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
}

.topbar-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top:-3rem;
}

.topbar-mitt {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:-3rem;
}

.topbar-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top:-3rem;
}


.top-bar-buttons {
    background: transparent;
    padding: 0;
    border: none;
}


//.topbar-mitt {
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    margin-left: 0;
//    width: 100%;
//    height: 6rem;
//    background-color: red;
//    margin-top: -20px !important;
//}


.top-bar-button,
.top-bar-button-green {
    outline: none;
    font-family: 'Roboto Condensed Extra Light', sans-serif;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12rem;
    height: 6rem;
    margin: 0;
    border: none;
    background-color: transparent !important;
    border-radius: 0;
    transition: background-color 0.5s;
    text-align: center;
    color: var(--gray-900);
}

.top-bar-button-green {
    background-color: rgba(140, 198, 62, 0.8) !important;
    color: var(--gray-900);
}

.top-bar-button .fa,
.top-bar-button-green .fa {
    margin-right: 5px; /* Spacing between icon and text */
    order: -1; /* Place icon before text */
}

.top-bar-button-green:hover,
.top-bar-button:hover {
    background-color: var(--orange-400) !important;
    color: black !important;
}

.top-bar-button-green:hover .fa,
.top-bar-button-green:hover .p-button-label,
.top-bar-button:hover .fa,
.top-bar-button:hover .p-button-label {
    color: black !important;
}

.top-bar-button:not(:last-child) {
    margin-right: 0;
}

.p-button-label {
    //font-family: 'Roboto Condensed Light', sans-serif;
    //font-size: 1.4rem;
    padding: 0;
    color: inherit; /* Use inherited color */
    display: flex;
    align-items: center; /* Center icon and text vertically */
}

.p-button-icon-left {
    margin-right: 5px; /* Adjust as needed */
    order: -1; /* Place icon before text */
}

.top-bar-button:focus, .top-bar-button-green:focus {
    outline: none; /* Remove focus outline */
    box-shadow: none; /* Remove any default focus shadow */
}

button:focus {
    outline: none; /* Remove focus outline */
    box-shadow: none; /* Remove any default focus shadow */
}

.op-donate-button {
    background: linear-gradient(to bottom, var(--indigo-500), var(--indigo-400));
    height:50px;
    color: white;
    border: none;
    margin: 0;
    border-radius: 8px;
    font-family: 'Roboto Condensed', sans-serif;
    //font-weight: 100;
    font-size: 1.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.op-donate-button-organization{ ;
    background: linear-gradient(to bottom, var(--bluegray-700), var(--bluegray-700));
    height:38px;
    color: white;
    padding-left:10px;
    border: none;
    margin: 10px;
    margin-top: -4px;
    border-radius: 0px;
    //font-family: 'Roboto', sans-serif;
    //font-weight: 400;
    //font-size: 1.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.op-donate-button-small{
    //background: linear-gradient(to bottom, var(--indigo-700), var(--indigo-400));
    background: linear-gradient(to bottom, var(--gray-100), var(--gray-200));
    height:39px;
    color: black;
    padding-left:10px;
    border: none;
    margin: 0;
    border-radius: 0px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.language-button {
    margin: 2px;
    height:40px;
    border: 0.5px solid #333;
    border-radius: 4px;
    background-color: #f5f5f5;
    color:gray;
}

.language-button .p-button-label {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    padding: 1px 1px;
    color: var(-text-blue-700);
}

.container {
    display: flex;
    align-items: center;
    width: 100%;
}

.image-container {
    flex: 0 0 20%;
    position: relative;
}

.p-image-center {
    width: 100%;
    height: auto;
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
}





.big-bold-txt {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    color: #404040;
    text-align: left;
    height: 30px;
    margin-top: 10px;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60%;
}

.small-bold-txt {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    color: #404040;
    text-align: left;
    height: 30px;
    margin-top: 10px;
    margin-bottom: 0;
    white-space: nowrap;       /* Ensure the text doesn't wrap to the next line */
    overflow: hidden;          /* Hide any overflow */
    text-overflow: ellipsis;   /* Add an ellipsis for any text overflow */
}

.grid-inner {
    //border: 2px solid red;
    //width: 100%;
    display: flex;
}

/* Styles for the first inner div */
.first-inner {
    //border: 2px solid blue;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    color: #404040;
    width: 50%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* Styles for the second inner div */
.second-inner {
    //border: 2px solid blue;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    color: #404040;
    width: 50%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.document-name {
    color:black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0;
    margin-right: 0;
    max-width: 80%;
    display: block;
}

// ****************** Image Gallery in Views ******************

.project-box {
    border:0px solid gray;
    border-radius:8px;
    border-top: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
}
.project-gallery-container  {
    position: relative;
    height: auto;
    aspect-ratio: 2 / 2;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
    z-index:2;
    top: -1px;
    left: -1px; /* Extend one pixel to the left to cover the border */
    right: -1px; /* Extend one pixel to the right to cover the border */
    width: calc(100% + 2px);
}

.project-gallery-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.gallery-container  {
    position: relative;
    height: 550px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
}

.gallery-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.gallery-nav-btn {
    position: absolute;
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Adjust positioning to be perfectly centered */
    background-color: rgba(255, 255, 255, 0.4); /* Slight white background */
    border: none;
    font-size: 18px;
    padding: 5px 8px;
    cursor: pointer;
    border-radius: 50%; /* Make buttons circular */
}

.gallery-nav-btn:hover {
    background-color: rgba(241, 109, 22, 0.99); /* Stronger background on hover */
}

.gallery-prev-btn {
    left: 10px; /* Position to the left */
}

.gallery-next-btn {
    right: 10px; /* Position to the right */
}

.gallery-container-no-round-corner  {
    position: relative;
    height: 350px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: hidden;
}

.gallery-container-no-round-corner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}


// ****************** Author Placed with name and Logo on bottom left position of Gallery

.author-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 32px;
    width: 100%;
    background: linear-gradient(to left, transparent 0%, var(--indigo-800) 100%, var(--indigo-400) 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #0e0e0e;
}

.author-label {
    border: 1px solid yellow;
    color:  #fefefe;
    text-align: left;
    margin-bottom: 1px;
    margin-left: 75px;
    margin-right: 0;
    font-family: 'Roboto   Light', sans-serif;
    font-size: 1rem;
}

.author-name {
    //border: 1px solid red;
    color:whitesmoke;
    margin-left: 60px;
    margin-right:30px;
    max-width: 80%;
    padding: 2px;
    margin-bottom: 1px;

    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.3s ease;
}

.author-container:hover .author-name {
    color: rgba(255, 255, 255, 0.5); /* Darker color on hover */
}

//  ****************** Author Placed with name and Logo on bottom left position of Gallery
.author-avatar-container {
    position: absolute;
    bottom: 6px;
    left: 6px;
    width: 48px;
    height: 48px;
    border-radius: 50%; /* Makes the container circular */
    display: flex;
    overflow: hidden; /* Hides the excess portion of the image */
    transition: filter 0.3s ease; /* Smooth transition for the filter */
}

.author-avatar-container img {
    object-fit: cover; /* Scales and crops the image */
    width: 100%;
    height: 100%;
}

.author-avatar-container:hover img {
    filter: brightness(70%); /* Reduces brightness to 70% on hover */
}


//  ****************** Trusted Placed with name and Logo on bottom left position of Gallery
.trusted-avatar-container {
    position: absolute;
    background-color: white;
    bottom: 6px;
    right: 6px;
    width: 48px;
    height: 48px;
    border-radius: 50%; /* Makes the container circular */
    display: flex;
    overflow: hidden; /* Hides the excess portion of the image */
    transition: filter 0.3s ease; /* Smooth transition for the filter */
}

.trusted-avatar-container img {
    object-fit: cover; /* Scales and crops the image */
    width: 100%;
    height: 100%;
}

.trusted-avatar-container:hover img {
    filter: brightness(70%); /* Reduces brightness to 70% on hover */
}


.p-fileUpload {

}


// ************ This is DateTime placed on top right corner over the image *****************

.group-sendmail {
    position: absolute;
    top: 0;
    left: 0;
    width:120px;
    height:32px;
    background-color: var(--indigo-800);
    display: flex;
    //align-items: center;
    text-align:left;
    padding:1px 0 2px 12px; /* top, right, bottom, left*/
    border-top-left-radius: 0; /* Rounded top-left corner */
    border-top-right-radius: 0; /* Rounded top-right corner */
    border-bottom-left-radius:0; /* Straight bottom-left corner */
    border-bottom-right-radius: 18px;
    opacity:0.8;
}



.group-datetime {
    position: absolute;
    top: 0;
    right: 0;
    width:140px;
    height:28px;
    opacity:0.9;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    text-align:right;
    padding: 0 0 2px 30px; /* top, right, bottom, left*/
    border-radius:0 8px 0 8px;
}


.group-datetime .pi {
    margin-left: 10px;
    color:  #fefefe;
}

.group-datetime-text{
    margin-left:-16px;
    color:white;
    padding: 0;
    text-align: center;
    width:150px;
    font-family: 'Roboto Condensed Medium', sans-serif;
    font-size:1.1rem;
    white-space: nowrap; /* Keeps the text on one line */
    text-overflow: ellipsis;
}

.money-button {
    display: inline-block;
    //background-color: #f5f5f5;
    color: #333;
    border: 1px solid #d4d4d4;
    padding: 5px 10px;
    border-radius: 0px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
    text-align: center;
}

.money-button:disabled {
    background-color: #d3d3d3; /* Lighter gray to indicate disabled */
    border-color: #a9a9a9; /* Darker border for disabled */
    color: #7d7d7d; /* Gray out the text */
    cursor: not-allowed; /* Show 'not allowed' cursor on hover */
}

.money-button:hover {
    background-color: #e0e0e0;
    border-color: #c1c1c1;
}

.money-button.selected {
    background-color: #007BFF;
    color: white;
    border-color: #0056b3;
}


.money-box {
    position: fixed; /* Fixed position */
    top:0; /* Position to 50% from the top */
    left: 50%; /* Position to 50% from the left */
    transform: translate(-50%, -1px);
    margin:10px;
    width: 80%;
    max-width: 450px;
    max-height: 90vh;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 15px;
    overflow-y: auto;
    z-index: 1001;
}


.money-box-mobile {
    position: fixed; /* Fixed position */
    top: 90px; /* Position to 50% from the top */
    left:10px; /* Position to 50% from the left */
    //transform: translate(-50%, -100%);
    margin:auto;
    width: 95%;
    padding: 0;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 1px;
    transform: translate(0%, -10%);
    overflow-y: auto;
    z-index: 1000;
}






.project-detail-box {
    position: relative;
    //top: 50%;
    //left: 50%;
    width: 100%;  /* or whatever width you want */
    //max-width: 1200px; /* or whatever max width you want */
    padding: 20px;
    //background-color: #fff;
    border: 0px solid #ccc;
    border-radius: 15px;
    //transform: translate(-50%, -16%);
}


.project-detail-box-mobile {
    position: relative;
    top: 0;
    left:0;
    right: 0;
    bottom: 5px;
    width: auto;
    margin: 0;
    padding: 0;
    background-color: #fff;
    border: 0 solid #ccc;
    border-radius: 4px;
    overflow-y: auto;
}

.input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center; /* To position it in the middle */
    width: 50%; /* To take up 50% of the width of its container */
    margin: 0 auto; /* Center the entire block horizontally */
}

.fixed-width-label {
    width: auto;
    white-space: nowrap;
    flex-shrink: 0;
    margin-right:5px;

    color: var(--gray-600);
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.1rem;
    margin-top: 10px;
//     margin-left: 30px;
//     margin-bottom: 0px;
}

.opop-field-height {
    flex-grow: 1;
    max-width: 180px; /* This will ensure the input field and its MKD label do not exceed 180px in width */
}

.custom-inputNumber .p-inputnumber {
    width: 100% !important;
    border-radius:0 !important;
    border-radius: 0.3rem;
}

.no-rounded-corners .p-inputnumber-input {
    border-radius: 0 !important;
    height: 28px;
    font-family: 'Roboto Condensed', sans-serif;
    color: var(--gray-800);
    font-size: 1rem;
    border: 1px solid var(--gray-200);
    width: 100%;
    margin-right:-1px;
}


.button-container {
    margin-top:52px;
    display: flex;
    justify-content: center;  /* Center the buttons in the container */
    flex-wrap: wrap;          /* Allow buttons to wrap to next line if container is too small */
    gap: 5px;                /* Create a 10px gap between each button */

}

.money-button {
    /* Previous styles of your button + any other styles you want to add */
}

.custom-message {
    /* Define your styles here */
    border: 1px solid #c0c0c0;
    padding: 0 10px 10px 10px; /* No padding-top */
    margin: 10px 0;
    border-radius: 5px;
    background-color: #f5f5f5;
}

.custom-message a {
    /* Styles for links inside the custom message */
    color: #007BFF;
    text-decoration: underline;
}

.title-message {
    /* Define your styles here */
    border: 1px solid var(--indigo-500);
    padding: 10px;
    margin: 0;
    border-top-left-radius: 10px; /* Rounded top-left corner */
    border-top-right-radius: 10px; /* Rounded top-right corner */
    border-bottom-left-radius: 0; /* Flat bottom-left corner */
    border-bottom-right-radius: 0; /* Flat bottom-right corner */
    color: whitesmoke;
    background-color: #007BFF;
}

#firstName_STOP {
    border: 1px solid #c0c0c0; /* Basic border */
    box-shadow: none; /* Remove shadow */
    //outline: none; /* Remove outline */
    //transition: none; /* Optional: Remove transition effects */
}

/* Ensure focused state has the same styling */
#firstName_STOP:focus {
    //border: 1px solid #c0c0c0; /* Maintain basic border */
    //box-shadow: none; /* Remove shadow */
    //outline: none; /* Remove outline */
}

.opop-insideTable {
    width: 100%;
    border-collapse: collapse;
}

.opop-insideTable td {
    padding: 5px; /* Adds some spacing within each cell */
}

.opop-insideTable .tableDataKey {
    width: 30%;
    text-align: left;
    vertical-align: top;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 100;
    font-size: 1.2rem;
    color: var(--gray-800);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.opop-insideTable .tableDataValue {
    text-align: left;
    vertical-align: top;
    font-family: 'Roboto Condensed Medium', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    color: var(--gray-900);
    white-space: normal; /* Allow wrapping */
    overflow-wrap: break-word; /* Ensure long words do not overflow */
}

.opop-insideTable tr:not(:last-child) {
    border-bottom: 0.5px solid var(--gray-200);
}

.opop-table {
    width: 100%;
    border-collapse: collapse;
}

.opop-table .tableDataKey {
    width: 30%;
    border-right: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
}

.opop-insideTable .tableDataKeyTitle{
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size: 1.1rem;
    color: var(--gray-900);
    background-color: var(--gray-50);
}

.opop-table .tableDataValue {
    width: 100%;
}

.opop-table td {
    padding: 4px; /* Adds some spacing within each cell */
}

.opop-table tr {
    height: 30px !important;
    padding: 4px;
}

.opop-table tr:not(:last-child) { /* Target all rows except the last one */
    border-bottom: 1px solid #e0e0e0; /* Change #e0e0e0 to any color you prefer */
}

.opop-inputlabel {
    width: 30%; /* Set the width for the left column */
}

.opop-inputlabel_large {
    font-family: 'Roboto Condensed Bold', sans-serif;
    color:var(--gray-700);
    font-size: 1.3rem;
    margin-top: 30px;
    margin-left: 2px;
    margin-bottom: 0px;
    // color:red;
    width: 100%; /* Set the width for the left column */
}

.opop-viewtext {
    width: 70%; /* Set the width for the right column */
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.0rem;
    margin-top: 12px;
    margin-left: 2px;
    margin-bottom: 3px;
}

.opop-viewtext-full {
    color: var(--gray-800);
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.3rem;
    margin-top: 5px;
    margin-left: 2px;
    margin-bottom: 3px;
    //line-height: 0.9rem;
    //white-space: pre-wrap;
    //word-wrap: break-word;
    // overflow-wrap: break-word;
}

.opop-table-cookie {
    width: auto;
    border-collapse: collapse;
}

.opop-table-cookie .tableDataKey {
    width: 30%;
    border-right: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
}

.opop-table-cookie .tableDataValue {
    width: 100%;
}

.opop-table-cookie td {
    padding: 4px; /* Adds some spacing within each cell */
}

.opop-table-cookie tr:not(:last-child) { /* Target all rows except the last one */
    border-bottom: 1px solid #e0e0e0; /* Change #e0e0e0 to any color you prefer */
}






/* Container for the logo and the sponsor text */
.sponsor-container {
    display: flex;
    //width:200px;
    align-items: center; /* vertically center the items */
    margin-left: 10px; /* adjust as needed */
    margin-bottom: 10px; /* adjust as needed */
}

/* Logo styles */
.sponsor-logo {
    width: 100px;
    height:18px;
}

/* Text to the right of the logo */
.sponsor-text {
    font-weight: bold; /* making the text bold */
    font-size: 16px; /* adjust as needed */
}



.footer {
    background-color: #fefefe;
    color: #0e0e0e;
    text-align: center;
    box-shadow: 5px -5px 5px -6px rgba(0, 0, 0, 0.3);
    padding: 0px;
    position: fixed;  /* Keeps the footer fixed at the bottom */
    bottom: 0;
    left: 0;  /* Ensure it spans the entire width from left to right */
    width: 100%;
    height: 50px;
    z-index: 1000;  /* Ensure it stays on top of other elements */
    display: flex;
    //align-items: center;
}


/* Container for the logo and the sponsor text */
.sponsor-container {
    position: absolute; /* Set the sponsor-container to be absolutely positioned */
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    margin-left: 30px;
    margin-bottom: 10px;
}

/* Logo styles */
.sponsor-logo {
    //border: 1px solid red;
    width: auto; /* adjust as needed */
    height: 40px; /* keeps the image aspect ratio */
    margin-right: 0; /* space between logo and sponsor text */
    margin-top: 2px;
    margin-bottom: 2px;
}

/* Sponsor Text */
.sponsor-text {
    width:100px;
    background-color: white;
    text-align: center;
    margin-left: -5px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    //border: 1px solid blue;
}


.footer-text {
    width:100%;
    //text-align: center;
    //margin-left: -5px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    //border: 1px solid blue;
}

.username {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0px;
    max-width: 250px; /* Adjust the width as per your requirement */
    display: inline-block;
}

.inner-table {
    width: 100%; /* Adjust the width as necessary */
    border-collapse: collapse; /* Ensure that the borders of the cells do not have space between them */
}

.inner-table td {
    padding: 2px 5px; /* Add some padding for better readability */
    vertical-align: top; /* Align the content of the cells to the top */
}

.inner-table td:first-child {
    text-align: left; /* Align the labels (first column) to the left */
    font-weight: bold; /* Make the labels bold */
}

.inner-table td:last-child {
    text-align: left; /* Align the data (second column) to the left */
}

.tableDataKeyIn {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 100;
    font-size: 1rem;
    color:var(--gray-700);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tableDataKey {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 100;
    font-size: 1.2rem;
    color:var(--gray-800);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tableDataValueIn {
    font-family: 'Roboto Condensed Medium', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color:var(--gray-900)   ;
    text-align: right;
    white-space: normal; /* Allow wrapping */
    overflow-wrap: break-word; /* Ensure long words do not overflow */
    max-width: 65%;
}

.tableDataValue {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    color:var(--gray-500)
}

.tableDataValue.right {
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size: 1.2rem;
    text-align: right;
    padding-right: 20px;
}

tableDataValue.center {
    text-align: left;
}

.tableDataHeader {
    text-align: left;
}

//text-900 font-medium text-xl line-height-3 mb-4

.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.sendMail {
    height: 2.5rem;
    min-width: 70px;
    padding: 4px 10px; /* Shorthand for setting top/bottom and left/right padding */
    border: 0.1px solid gray;
    border-radius: 8px !important;
    margin-right:10px;
    margin-top:0;
    //background-color: var(--indigo-500);
    background-color: transparent;
    color:var(--indigo-500);
}

.sendMailSmall {
    height: 2rem;
    width: 80px;
    padding:8px;
    margin-right:4px;
    margin-left: auto;
    margin-top:0;
    background-color: transparent;
    color:#fefefe;
    border:0;
}

.sendMailSmallTop {
    height: 2rem;
    max-width: 120px;
    padding:8px;
    margin-right:4px;
    margin-left:auto;
    //margin-top:-50;
    background-color: transparent ;
    border: 0;
    color:#fefefe;
    width:100px;
}



.article-main-head {
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size: 2rem !important;
    margin-top: 20px;
    margin-left: 0;
    line-height: -3.5;  /* line-height-3 */
    margin-bottom: 0.1rem;
    max-width: 90%;
    color:var(--gray-800);
}

.article-head {
    font-weight: 700; /* Assuming text-700 means a font-weight of 700 */
    font-size: 1.1rem; /* Assuming text-l means a large font size */
    line-height: 0;  /* line-height-3 */
    margin-bottom: 0.5rem;
    width: 100%;     /* Full width on all screens */
}



.main-article-subhead {
    font-family: 'Roboto Condensed Light', sans-serif;
    font-size: 1.2rem;
    line-height: -3.5;  /* line-height-3 */
    margin-top: 0;   /* mt-0 */
    margin-bottom: 1.25rem; /* mb-5 (assuming 1rem = 8px) */
    width: 100%;     /* Full width on all screens */
}

@media screen and (min-width: 992px) { /* Adjust breakpoint as needed */
    .main-article-subhead {
        max-width: 100%;  /* Max width of half screen on large screens */
        margin-left: 0; /* Center the div */
        margin-right: auto;
    }
}

.chart-container {
    display: flex;
    justify-content: center;
    text-align: center; /* Center the chart horizontally */
}



//.close-btn {
//    background-color: white;
//    color: black;
//    border:4px solid #007BFF;
//    box-sizing: border-box !important;
//    position: absolute;
//    top: 10px;
//    right: 10px;
//    padding:5px;
//    width: 20px;
//    height: 37px;
//    border-radius: 50%;
//}


.close-btn {
    background-color: white;
    color: black;
    border: 2px solid #007BFF;
    box-sizing: border-box !important;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    width: 37px; /* Ensure it is square */
    height: 37px; /* Ensure it is square */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    z-index: 1002;
    cursor: pointer;
}

.close-btn .fas {
    font-size: 16px; /* Ensure the icon is appropriately sized */
}
.fundraising-progress {
    margin: 0 20px;
}
.labels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.raised {
    background-color: #007BFF;
    color: #fff;
}
.remaining {
    background-color: #FFA500;
    color: #fff;
}
.total {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
}


.p-buttonset {
    width: 100% !important;
    display: flex !important;
}

.p-buttonset .p-button {
    flex: 1; /* This will make each button take equal width */
}

.button-level2 {
    height: 3rem;
    min-width: 70px;
    padding: 4px 10px; /* Shorthand for setting top/bottom and left/right padding */
    border: 0.1px solid gray;
    border-radius: 0 !important;
    background-color: var(--indigo-500);
}


.donationLabel {
    color: var(--gray-600);
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.1rem;
    margin-top: 20px;
    margin-left: 30px;
    margin-bottom: 0px;
}

.donationText {
    color: var(--gray-600);
    font-family: 'Roboto Condensed', sans-serif;
//     font-weight: 200;
    font-size: 1.1rem;
    margin-top: 5px;
    margin-left: 30px;
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5); /* Dimmed background */
    z-index: 1000; /* Make sure this is above other content but below the payment component */
}


.theHeader {
    background-color: var(--primary-100);
    height: 60px;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    //background: $tableHeaderBg;
    //color: $tableHeaderTextColor;
    //border: $tableHeaderBorder;
    //border-width: $tableHeaderBorderWidth;
    //padding: $tableHeaderPadding;
    //font-weight: $tableHeaderFontWeight;
}

@media only screen and (max-width: 1024px) {
    .desktop-only {
        display: none;
    }
}


.p-steps .p-steps-item {
    background-color: transparent !important;
}


.full-width {
    width: 100%;
}

.full-width-input {
    margin: 0 20px;
}


.custom-float-label {
    position: relative;
    padding-top: 1.5rem;
    width: 100%;
}

.custom-float-label input {
    width: 100%;
    padding: 0.5rem 0.5rem 0.5rem 2rem;
}

.custom-float-label label {
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s;
    pointer-events: none;
    font-size: 1rem;
    color: #999;
}

.custom-float-label input:focus ~ label,
.custom-float-label input:not(:placeholder-shown) ~ label {
    transform: translateY(-1.5rem);
    font-size: 0.75rem;
}

.custom-icon {
    position: absolute;
    top: 1rem;
    left: 0.5rem;
    transition: 0.2s;
}

.horizontal-line {
    width: 100%; /* Make the line take the full width of its container */
    border: 0; /* Remove the default border */
    border-top: 0.5px solid var(--gray-300); /* Add a top border to create the line */
    margin: 0; /* Remove default margin */
    margin-bottom: 10px;
}


.horizontal-line-margins::after {
    content: "";
    display: block;
    height: 0.5px;
    width: 100%;
    background: linear-gradient(to right, var(--gray-200), var(--gray-50));
    margin-bottom: 10px;
    margin-top: 3px;
}

.horizontal-line-thick {
    width: 100%; /* Make the line take the full width of its container */
    border: 0; /* Remove the default border */
    border-top: 5px solid var(--orange-600); /* Add a top border to create the line */
    margin: 0; /* Remove default margin */
    margin-bottom: 10px;
}

.horizontal-line-about::after {
   content: "";
   display: block;
   height: 2px;
   width: 100%;
   background: linear-gradient(to right, var(--orange-500), var(--gray-50));
   margin-bottom: 10px;
   margin-top: 3px;
}
.horizontal-line-donate{
   width: 100%; /* Make the line take the full width of its container */
   border: 0; /* Remove the default border */
   border-top: 5px solid linear-gradient(to right, var(--gray-200), var(--gray-50));
//    background: linear-gradient(to right, var(--gray-200), var(--gray-50)); /* Add a top border to create the line */
   margin: 0; /* Remove default margin */
   margin-bottom: 10px;
}

.horizontal-line-thick-margins::after {
    content: "";
    display: block;
    height: 5px;
    width: 100%;
    background: linear-gradient(to right, rgb(0,170, 171), var(--gray-50));
    margin-bottom: 10px;
    margin-top: 3px;
}

.horizontal-line-thick-margins-white::after {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    background: linear-gradient(to right, var(--orange-500), white);
    margin-bottom: 10px;
    margin-top: 3px;
}

.horizontal-line-thick-margins-indigo::after {
    content: "";
    display: block;
    height: 5px;
    width: 100%;
    background: linear-gradient(to right, var(--indigo-500), white);
    margin-bottom: 10px;
    margin-top: 3px;
}

.horizontal-line-thick-margins-teal::after {
    content: "";
    display: block;
    height: 5px;
    width: 100%;
    background: linear-gradient(to right, var(--teal-700), white);
    margin-bottom: 10px;
    margin-top: 3px;
}

.textarea-container {
    padding:0;
    margin:0;
}

.textarea-container textarea {
    width: 100%; /* Make the textarea occupy full width of the container */
    border: none; /* Remove borders */
    overflow-y: auto; /* Show vertical scrollbar when needed */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    resize: none; /* Disable resizing of the textarea */
    margin-bottom: 5px;
}

.uploadPanel {
    padding: 0;
    margin:0;
    width:40px;
    height: 66px;
    background-color: #0d3c61;
    border:2px solid blue;
}

.button-container-donate {
    width:  calc(100% - 60px);
    margin-top:10px;
    margin-left:30px;
    margin-right:30px;
    margin-bottom:10px;
    display: flex;
    justify-content: center;
}

.center-button-donate  {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-container-donate  > p-button,
.button-container-donate  > button {
    width: 48%; /* slightly less than 50% to account for any padding/margin */
    box-sizing: border-box;
}

.button-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.button-container-mail {
    display: flex;
    justify-content: center;
    width: 100%;
    height:38px;
}

.mailer-buttons {
      font-family: 'Roboto Condensed' , sans-serif;
      color: white;
      background-color:var(--gray-500);
      border:0;
      font-size: 1rem;
      margin-left: 12px;
      border-radius: 4px !important;
  }

.center-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-container > p-button,
.button-container > button {
    width: 48%; /* slightly less than 50% to account for any padding/margin */
    box-sizing: border-box;
}

.title-container-float {
    position: relative;
}

.sendMail-float  {
    float: right; /* Float the button to the right */
    height: 2.5rem;
    min-width: 70px;
    padding: 4px 10px;
    border: 0.1px solid gray;
    border-radius: 8px !important;
    margin-top: 0; /* Align button to top */
    background-color: transparent;
    color: var(--indigo-500);
    position: absolute; /* Position the button absolutely within the title-container */
    top: 0; /* Align top of the button with the top of the title-container */
    right: 0; /* Align right of the button with the right of the title-container */
}

/* Clearfix for the float */
.title-container-float ::after {
    content: "";
    display: table;
    clear: both;
}



.language-dropdown {
    background-color: transparent;
    border: 0 !important;
}

.language-dropdown .p-dropdown {
    background-color: transparent;
    border: 0 solid white;
    width: 70px !important;
    margin-left: 0;
    margin-right:0;
    padding: 0 !important;
}


.language-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding:1px;
    white-space: nowrap;
}

.language-dropdown .p-dropdown-label {
    padding: 1px;
    margin: 1px;
    color: var(--gray-900);
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.4rem;
    //margin-top: -5px;
    margin-left: 2px;
}

.language-dropdown .p-dropdown .p-dropdown-items .p-dropdown-item {
    font-family: 'Roboto Condensed Bold' , sans-serif;
    color: var(--gray-900);
    font-size: 1.4rem;
    margin-left: 2px;
}

.language-dropdown .p-dropdown.p-component:focus,
.language-dropdown .p-dropdown.p-component:active {
    background-color: transparent;
    border: 0;
    outline: none;
    box-shadow: none;
}

/* If there's a specific class applied to the focused dropdown, you can use that as well */
.language-dropdown .p-dropdown.p-focus {
    outline: none;
    box-shadow: none;
}
.language-dropdown .p-dropdown.p-focus .p-dropdown-label,
.language-dropdown .p-dropdown.p-focus .p-dropdown-panel {
    outline: none;
    border: none;
    box-shadow: none;
}

.language-dropdown .p-dropdown .p-dropdown-trigger {
    top:2px;
    right: 10px; /* Move the icon 20px to the left */
    position: absolute;
}

.language-dropdown .p-dropdown .p-dropdown-trigger-icon {
    display: none;
}



.language-dropdown .p-dropdown .p-dropdown-trigger::before {
    content: "\f0d7"; /* Unicode for Font Awesome down caret */
    font-family: "Font Awesome 5 Free"; /* Ensure it uses Font Awesome */
    font-weight: 900; /* Bold weight */
    color:var(--gray-900);
    font-size: 1.4em;
    //margin-top:-4px;
}

.styled-textarea {
    font-size: var(--font-size-sm);
    line-height: 1.5;
    width: 100%; /* Full width */
    border: none; /* No border */
    outline: none; /* No focus outline */
    overflow-y: auto; /* Vertical scrollbar */
    overflow-x: hidden; /* No horizontal scrollbar */
    resize: none; /* Disable resize handle */
    background-color: transparent; /* No background */
    padding: 0; /* Adjust padding as needed */
    margin: 0; /* Adjust margin as needed */
    box-shadow: none; /* No box shadow */
    color: inherit; /* Text color inherited from parent */
}

.styled-textarea::-webkit-scrollbar {
    width: 12px; /* Width of scrollbar */
}

.styled-textarea::-webkit-scrollbar-thumb {
    background-color: #c1c1c1; /* Color of scrollbar thumb */
    border-radius: 6px; /* Rounded scrollbar thumb */
}

::ng-deep .filter-dropdown .p-dropdown {
    border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
}

.filter-dropdown .p-dropdown {
    border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
}


.custom-autoComplete .p-autocomplete-input {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
    border-radius: 0;
    border: 0.2px solid var(--gray-200);
}
.custom-autocomplete .p-autocomplete {
    outline: none;
}
.custom-autocomplete .p-autocomplete-input-token {
    border-radius: 0.3rem; /* You can adjust the value as needed */
}


.square-autocomplete .p-autocomplete-input {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
    border-radius: 0;
    height: 30px;
}
.square-autocomplete .p-autocomplete {
    border-radius: 0;
    outline: none;
    height: 30px;
}
.square-autocomplete .p-autocomplete-input-token {
    border-radius: 0; /* You can adjust the value as needed */
    height: 30px;
}
.square-autocomplete .p-autocomplete-dropdown {
    background:var(--indigo-500);
    height: 30px !important;
    line-height: 10px !important;
    padding: 0 10px !important; /* Adjust horizontal padding as needed */
}
.square-autocomplete .p-autocomplete-dropdown .p-icon {
    background:var(--indigo-500);
}


.custom-small-text {
    margin-left: 10px;
}

.custom-autocomplete .p-autocomplete-input-token {
    border-radius: 0.3rem; /* You can adjust the value as needed */
}

.custom-calendar .p-inputtext {
    margin-top: 0px;
    margin-bottom: 0;
    margin-right: 0;
    padding: 0.5rem;
    border-radius: 0.3rem;
    border: 0.2px solid var(--gray-200);
    // height: 2rem;
}

.custom-calendar .p-button {
    background-color: var(--indigo-500); /* Replace #yourColor with your desired color */
}

.custom-calendar .p-button:focus {
    outline: none;
    box-shadow: none; /* Sometimes the focus state is also indicated by a box-shadow */
}

.custom-calendar .p-inputtext:focus {
    outline: none;
    box-shadow: none;
}

.custom-dropdown {
   border:1px solid orange;
}



.custom-dropdown .p-inputtext:focus {
    outline: none !important;
    box-shadow: none !important;
}

.no-outline .p-dropdown .p-dropdown-label.p-inputtext:focus,
.no-outline .p-dropdown .p-dropdown-panel:focus,
.no-outline .p-dropdown:focus {
    outline: none !important;
    box-shadow: none !important;
}


.page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.page-title {
    /* Title styles */
    margin-right: auto; /* Pushes everything else to the right */
}

.controls-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.filter-search-group {
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Ensure that the controls-container does not shrink smaller than its content */
.controls-container {
    flex-shrink: 1;
}

.searchBar {
    display: flex; /* This turns searchBar into a flex container */
    align-items: center; /* This aligns the children vertically in the center */
    margin-left: 10px; /* This can be adjusted or removed based on your design */
}

.searchBar .p-inputgroup-addon {
    background-color: var(--indigo-500);
    color: var(--primary-color-text);
    border-radius: 5px 0 0 5px;
    padding: 2px;
    margin: 0; /* Remove margin to have elements fit snugly together */
    /* Remove 'margin-left' from here, it's not needed if searchBar is a flex container */
}

.searchBar .p-dropdown {
    width: 135px;
    height: 31px;
    border-radius: 0px 5px 5px 0px;
    margin: 0; /* Remove margin to have elements fit snugly together */
}


.custom-inputNumber .p-inputtext {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.1rem;
    margin-top: 0px;
    margin-bottom: 0;
    margin-right: -1px;
    padding: 0.5rem;
    text-align: right;
}

.p-inputwrapper.p-dropdown .p-dropdown:not(.p-disabled):focus,
.p-inputwrapper.p-dropdown .p-dropdown:not(.p-disabled):active {
    outline: none !important;
    border: none !important; /* Overrides border */
    box-shadow: none !important; /* Overrides box-shadow */
}

.ui-dropdown-box.ui-state-focus {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.p-dropdown .p-hidden-accessible .p-element:focus {
    outline: none !important;
    box-shadow: none !important;
}
.no-outline:focus {
    outline: none !important;
    box-shadow: none !important;
}

body .p-dropdown.p-component.p-focus,
body .p-dropdown.p-component .p-dropdown-panel.p-focus,
body .p-dropdown.p-component:focus ,
body .p-dropdown.p-component:focus{
    outline: none !important;
    box-shadow: none !important;
}

body .p-inputtext:focus {
    outline: none !important;
    box-shadow: none !important;
}
//
//.top-wrapper {
//    display: flex;
//    align-items: center; /* Vertically center the children */
//    position: relative; /* Needed for absolute positioning of children */
//    width: 100%; /* Occupy full width of the enclosing container */
//    height: 50px; /* Fixed height */
//    //z-index: 5099 !important;
//}
//.checkbox-group {
//    display: flex;
//    gap: 10px;
//    align-items: center;
//}

.top-wrapper .checkbox-group {
    display: flex;
    gap: 5px;
    align-items: center;
}

.checkbox-group p-checkbox {
    display: flex;
    align-items: center;
}

.controls-container .filter-search-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}




.top-left {
    display: flex;
    align-items: center; /* Vertically align content in the center */
    justify-content: flex-start; /* Align content to the far left */
    height: 100%; /* Match height of top-wrapper */
    /* Add a fixed width if needed, or use padding */
    margin-right:10px;
    /* Left margin is not needed as it's already at the far left */
}

.top-centred {
    display: flex;
    align-items: center; /* Vertically align content in the center */
    justify-content: center; /* Center the content horizontally */
    position: absolute; /* Take out of normal document flow */
    left: 50%; /* Start at 50% of the parent's width */
    transform: translateX(-50%); /* Adjust to true center */
    height: 100%; /* Match height of top-left */
    z-index: 0099 !important;
}

/* Style individual elements within top-left and top-centred if needed */
.top-left > *, .top-centred > * {
    margin: 0; /* Margins between elements */
}

.p-dropdown .p-dropdown-panel {
    background: white !important;
    z-index: 99999 !important;
}

.p-tabview {
   margin-top:80px;
}

.p-tabview .p-tabview-nav li {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

/* Change text color for inactive tabs, if necessary */
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: #757575; /* Example color for inactive tabs */
}

/* Change text color for the active tab */
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #007ad9; /* Example color for active tab */
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

/* Remove border from the content part of the tab panels */
.p-tabview .p-tabview-panels {
    border: none !important;
}


/* Target the trigger part of the dropdown and set its width */
.target-dropdown {
    background:transparent !important;
    border:0;
}
.target-dropdown .p-dropdown-trigger {
    width: 30px; /* Width of the arrow area */
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background:transparent !important;
    border:0;
}

/* Ensure the text is not visible */
.target-dropdown .p-dropdown-label {
    text-indent: -999em;
    overflow: hidden;
    background:transparent !important;
    border:0;
}

/* Set the width of the dropdown panel to be wider */
.target-dropdown .p-dropdown-panel {
    width: 200px !important; /* Width of the dropdown panel */
}
/* Override the dropdown icon */
.target-dropdown .p-dropdown-trigger .pi {
    /* Remove the default chevron icon */
    display: none;
}

.targetStop-dropdown .p-dropdown-trigger:after {
    /* Use a pseudo-element to create the new icon */
    content: "\e91a"; /* This is the content code for 'pi-chevron-down' */
    font-family: "PrimeIcons"; /* Set the font family to PrimeIcons */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

.virman-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.virman-container {
    max-width: 800px; /* Adjust the fixed width */
    max-height: 600px; /* Adjust the fixed height */
    margin: auto; /* Center in the viewport */
    overflow: auto; /* Add scroll for overflow content */
    background-color: var(--pink-50);
    border:1px solid lightgrey;
    padding:20px;
}

.welcome-dialog {
    color:red;
}

.welcome-dialog .p-dialog-title {
    border: 2px solid red;
    font-size: 12px; /* Adjust the font size as needed */
    padding: 20px; /* Adjust the padding to increase space */
}

.welcome-dialog .p-dialog-content {
    height: calc(100% - 10px); /* Adjust content height based on new header size */
}


.col-nr-head             { width:30px  !important; }
.col-nr-ID               { width:200px  !important; }
.col-date-head           { width:80px  !important; }
.col-type-cpay           { width:80px  !important; }
.col-type-head           { width:55px  !important; }
.col-amount-head         { width:120px !important; }
.col-donor-head          { width:160px !important; }
.col-target-head         { width:150px !important; }
.col-balance-head        { width:90px !important; }
.col-status-head         { width:60px  !important; }
.col-agreement-head      { width:40px !important; }
.col-receipt-head        { width:40px !important; }

.col-nr-head, .col-date-head, .col-type-cpay, .col-donor-head, .col-type-head, .col-amount-head,
.col-target-head, .col-balance-head, .col-status-head, .col-agreement-head, .col-receipt-head {
    padding-left:0px !important;
    margin:0 !important;
    height:10px;
    text-align: left  !important;
    font-family:'Roboto Condensed Bold',sans-serif !important;
    font-size: 1rem !important;
}

.col-nr-data, .col-date-data, .col-donor-data, .col-type-data, .col-amount-data,
.col-target-data, .col-balance-data, .col-status-data, .col-agreement-data, .col-receipt-data  {
    padding-top:6px    !important;
    padding-bottom:6px !important;
    padding-left:4px    !important;
    margin:0 !important;
    height:20px;
    text-align: left;
    vertical-align: top;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    font-family:'Roboto Condensed',sans-serif !important;
    font-size: 1rem !important;
}

.tableHeaderColumnStyle {
    padding: 0 !important;
    margin: 0 !important;
    vertical-align: center !important;
    text-align:left !important;
    font-family: 'Roboto Condensed', sans-serif !important;
    font-size: 0.9rem !important;
    height: 1rem;
}

.tableDataColumnStyle {
    padding: 0 !important;
    padding-top:5px !important;
    padding-bottom:5px !important;
    margin: 0 !important;
    vertical-align: top !important;
    text-align:left !important;
    font-family: 'Roboto Condensed', sans-serif !important;
    font-size: 1.1rem !important;
    //height: 1.1 rem;
    white-space: pre-wrap;
}

.col-agreement-data {
    height:20px;
    padding-top:2px    !important;
    padding-bottom:2px !important;
    padding-left:2px    !important;
    margin:0 !important;
    //font-size: 1.1rem !important;
    //display: flex;
    //justify-content: center;
    //align-items: center;
}

.col-receipt-data {
    height:20px;
    padding-top:2px    !important;
    padding-bottom:2px !important;
    padding-left:2px    !important;
    margin:0 !important;
    //display: flex;
    //justify-content: center;
    //align-items: center;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: grey !important;
}

.p-datatable .p-datatable-thead > tr {
    height: 50px; /* or any other value */
}


.p-datatable .p-datatable-table {
    table-layout: fixed;
}

.custom-transactions-table .p-datatable-thead > tr > th,
.custom-transactions-table .p-datatable-tbody > tr > td {
    border-right: 1px solid #dee2e6; /* adjust color as needed */
}

.custom-transactions-table .p-datatable-thead > tr > th:last-child,
.custom-transactions-table .p-datatable-tbody > tr > td:last-child {
    border-right: none;
}

.no-margin-padding {
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    background: none !important;
}


.opop-cell-box {
    flex: 1;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: -4px;
    margin-bottom: 1px;
    border-radius: 4px;
    padding: 4px;
    text-align: right;
    height: 22px;
    border: 0.5px solid var(--gray-200);
    display: flex;        /* Enable Flexbox */
    align-items: center;  /* Vertically center the content */
    justify-content: flex-start;
}


.opop-cell-box-state {
    background-color: white;

}

.opop-cell-box-amount {
    background-color:whitesmoke;
    text-align: right;
    justify-content: flex-end;
}

.opop-cell-box-balance {
    background-color: whitesmoke;
    justify-content: center;
}


.wallet-buttons-container {
    margin:0;
    display: flex;
    flex-wrap: wrap; /* Allow the container to wrap elements */
    justify-content: space-around; /* Distribute buttons evenly */
    align-items: center;
    background-color: whitesmoke;
    padding: 2px; /* Optional: Adds padding around the buttons */
}



.wallet-column-button {
    background-color: linear-gradient(to bottom, var(--pink-500), var(--pink-400));
    padding-left: 10px;
    padding-right: 10px;
    height: 15px;
    width: 240px;
    color: var(--blue-500);
    border: none;
    margin: 5px 2px 2px;
    border-radius: 4px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wallet-button {
    background-color: linear-gradient(to bottom, var(--pink-500), var(--pink-400));
    padding-left: 10px;
    padding-right: 10px;
    height:32px;
    width:240px;
    color: white;
    border: none;
    margin: 5px 2px 2px;
    border-radius: 4px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wallet-button-topUp {
    background-color: linear-gradient(to bottom, var(--teal-500), var(--teal-400));
    padding-left: 20px;
    padding-right: 20px;
    height:40px;
    color: white;
    border: none;
    margin: 5px 2px 2px;
    border-radius: 4px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 100;
    font-size: 1.15rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sumMoney {
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-weight: 100;
    font-size: 1.15rem;
    white-space: nowrap;
    color:var(--gray-0);
    margin: 5px 2px 2px;
}



.dialog-content {
    margin-bottom: 80px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    padding-bottom: 20px; /* Adjust as necessary based on the height of your footer */
    overflow-y: auto;
    width: 100%;
}

p-footer {
    //position: absolute;
    position: fixed;
    padding: 20px;
    bottom: 0;
    left:10px;
    width: 95%;
    //z-index: 10; /* Ensure it floats above other content */
    background: rgba(255,255,255,0.98); /* You can make it slightly transparent or adjust to your needs */
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); /* Optional: for a shadow effect at the top of the footer */
}



.circle {
    background-color: var(--orange-500); /* Dark background */
    color: white; /* White text */
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-weight: 600;
    //font-size: 1.3rem;
    //font-weight: bold; /* Bold font */
    font-size: 20px; /* Large font size */
    width: 25px; /* Width of the circle */
    height: 25px; /* Height of the circle */
    border-radius: 50%; /* Round corners to make it a circle */
    display: flex; /* Flexbox to center content */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    margin: 10px; /* Margin for spacing */
}



.custom-full-width-table {
    //width: calc(100% - 2px); /* full width minus margin on both sides */
    margin-bottom: 30px;
    margin-left: 0;
    min-width: 25rem;/* same as the table width */
    max-width: 100rem;
    width:55rem;
    //height:380px;
    font-size:14px;
    //border: 1px solid #ccc;
}

.custom-table .p-datatable-thead > tr > th {
    background-color:var(--indigo-500); /* Replace #yourColor with your desired color */
    color: white; /* Replace #yourTextColor with the color you want for the text */
}

.custom-full-width-table .p-datatable-tbody > tr {
    height: 10px; /* or any height you prefer */
}
.custom-full-width-table .p-datatable-tbody > tr > td {
    font-size:small;
    padding:6px;
    margin:0;
}

.custom-full-width-table .p-datatable .p-datatable-wrapper {
    //border-radius: 30px; /* or any other value you prefer */

}

.custom-full-width-table .p-datatable-scrollable-body {
    /* Adjust the scrollable body if necessary */
    background-color: #f2f2f2;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    //box-shadow: inset 0 0 10px rgba(0,0,0,0.1);
}

.tableHeaderBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15rem;
    padding-top: 3px;
    padding-bottom: 3px;

    height: 40px;
    min-width: 45rem;/* same as the table width */
    margin-bottom: 0px;
    background-color: var(--indigo-500);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px; /* to vertically center the text */
    padding-left: 12px; /* for some spacing before the text */
}

.header-button {
    //width: 120px;
    min-width: 18rem;
    height: 26px;
    //border-radius: 0 10px 0 0;
    border-radius: 15px;
    color: whitesmoke;
    background-color: var(--pink-500);
    border:0.5px solid  var(--pink-300);;

    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
    box-shadow: none;
}

.main-container {
    background-color: transparent;
    border-top: 1px solid lightgray; /* 1px gray line on top */
    padding:20px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1024px) {
    .main-container {
        padding: 0;
        margin: 0;
    }
}

@media screen and (max-width: 1200px) {
    .left-column, .right-column {
        flex: 0 0 100%;
        max-width: 90%;
        margin:auto;
    }
}

.main-card {
    background-color: transparent;
    width: 100%;
    max-width: 100%; /* Ensure it doesn't exceed 100% of the container width */
}

.left-column {
}

.right-column {
    background-color: var(--gray-50);
    border-radius: 0 10px 10px 0;
}


.donate-beneficiary-button-container {
    margin:auto;
    width:80%;
}

.donate-beneficiary-button {
    text-align: center; /* Center text horizontally */
    line-height: 20px; /* Adjust the line height to vertically center the text */
    padding: 10px 20px;
    background: linear-gradient(to bottom, var(--red-500), var(--red-400)); /* Vertical gradient from dark red to light red */
    border: 0 solid var(--pink-500); /* White border */
    border-radius:  1px;
    color: white; /* White text color */
    font-size:18px;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-weight: 700;
    cursor: pointer; /* Cursor style on hover */
    transition: background 0.3s ease; /* Smooth background color transition */
}

.payout-beneficiary-button {
    width:220px;
    height: 40px;
    margin:40px 10px 2px 0;
    text-align: center; /* Center text horizontally */
    line-height: 20px; /* Adjust the line height to vertically center the text */
    padding: 10px 10px;
    background: linear-gradient(to bottom, var(--primary-500), var(--primary-400)); /* Vertical gradient from dark red to light red */
    border: 0 solid var(--pink-500); /* White border */
    border-radius: 5px;
    color: white; /* White text color */
    font-size:16px;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-weight: 700;
    cursor: pointer; /* Cursor style on hover */
    transition: background 0.3s ease; /* Smooth background color transition */
}

.donate-beneficiary-button:hover {
    background: linear-gradient(to bottom, #FF0000, #AA0000); /* Reverse gradient on hover */
}



.prise-beneficiary-button-container {
    margin: auto;
    //margin-left:-50px;
    margin-top:25px;
    //margin-bottom:50px;
    width:300px;
}

.prise-beneficiary-button {
    text-align: center; /* Center text horizontally */
    line-height: 20px; /* Adjust the line height to vertically center the text */
    padding: 10px 20px;
    background: linear-gradient(to bottom, var(--gray-500), var(--gray-300)); /* Vertical gradient from dark red to light red */
    border: 2px solid white; /* White border */
    color: white; /* White text color */
    font-size:18px;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-weight: 700;
    cursor: pointer; /* Cursor style on hover */
    transition: background 0.3s ease; /* Smooth background color transition */
}

.prise-beneficiary-button:hover {
    background: linear-gradient(to bottom, #FF0000, #AA0000); /* Reverse gradient on hover */
}


.raised-button {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Add box shadow for the raised effect */
}


.input-grp-donate {
    width: 100%;
    margin-top: 2px;
    margin-bottom: 8px;
}

.p-inputgroup {
    border-radius:0;
}

.input-frm-donate {
    padding: 0 0 0 10px;
    border: 1px solid var(--gray-100);
    color: var(--gray-600);
    background-color: var(--gray-50);
    border-radius:4px 0 0 4px;
    width: 160px;
    height:28px;
    margin-left:30px;
    margin-right:0px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1rem;
    justify-content: start;
}

.input-txt-donate {
    height: 28px;
    font-family: 'Roboto Condensed', sans-serif;
    color: var(--gray-800);
    font-size: 1rem;
    border-radius: 0 !important;
    border: 1px solid var(--gray-200);
    width: 100%;
    margin-left:-1px;
    margin-right:30px;
}


.input-grp {
    width: 100%;
    margin-top: 2px;
    margin-bottom: 8px;
}

.input-frm {
    padding: 0 0 0 10px;
    border: 0;
    border-radius: 7px 0 0 7px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.1rem;
    color: var(--gray-500);
    background-color: var(--gray-50);
    width: 15rem;
    justify-content: start;
}

.input-icon {
    font-size: 12px;
    margin-right:11px;
}

.input-txt {
    height: 28px;
    font-family: 'Roboto', sans-serif;
    color: var(--gray-700);
    font-size: 1.1rem;
    border-radius: 0 !important;
}





.input-p-dropdown .p-dropdown-label,
.input-p-dropdown .p-dropdown-trigger {
    padding:6px 0 0 6px!important;
    height: 28px;
    font-family: 'Roboto', sans-serif;
    color: var(--gray-700);
    font-size: 1.1rem;
    border-radius: 0 !important;
}

.add-person-button {
    width: 150px;
    margin-bottom:0;
    padding: 10px; /* Adjust padding as needed */
    background-color: #007bff; /* Button background color */
    color: #fff; /* Button text color */
    border: none;
    cursor: pointer;
    font-size: 16px; /* Adjust font size as needed */
}
.add-person-button:hover {
    background-color: #0056b3; /* Change color on hover */
}
.finance-top-container {
    display: flex;
    justify-content: space-between; /* Aligns children to each end */
    align-items: center; /* Aligns children vertically in the middle */
}
.payout-beneficiary-button {
    /* Your existing styles for header-button */
    margin-left: auto; /* This pushes the button to the far right */
}

.characterCounter {
    font-family: 'Roboto Condensed Light', sans-serif;
    color: var(--gray-900);
    float: right;
    font-size: 1.2rem;
    margin-bottom: 0;
    padding-bottom: 0;
}


.displayId {
    font-family: 'Roboto Condensed Light', sans-serif;
    color: var(--gray-900);
    font-size: 0.9rem;
    margin-bottom: 0;
    padding-bottom: 0;
}

.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center; /* This ensures vertical alignment if needed */
}

.p-inputgroup {
    margin-top:5px;
}


.right-inputgroup {
    width: 150px;
    height: 26px;
    margin-top: 35px;
}
.right-addon {
    border:0;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size:1.2rem;
    border-radius: 0 8px 8px 0;
    background-color:var(--primary-500);
    color:white;
}
.dateRange-container {
    display: flex;
    justify-content: flex-end; /* Aligns items to the right */
    align-items: center; /* Aligns items vertically in the center */
}

.calendar-item {
    margin-left: 5px; /* Adds some space between items */
    display: flex;
    align-items: center; /* This ensures vertical alignment of text and calendar */
}
.total-funds-input {
    border-radius: 2px 0 0 2px !important;
}
.total-funds-input .p-inputnumber-input {
    text-align: right;
    font-family: 'Roboto Condensed', sans-serif;
    font-size:1.2rem;
    border-radius: 2px 0 0 2px;
}
.calendar-input {
    width:150px;
    height:26px;
}
.calendar-input .p-inputtext {
    text-align: right;
    font-family: 'Roboto Condensed', sans-serif;
    font-size:1.2rem;
    border-radius: 2px 0 0 2px;
    height:26px;
}
.calendar-input .p-button {
    text-align: right;
    font-family: 'Roboto Condensed', sans-serif;
    border-radius: 0 8px 8px 0;
    border:0;
    height:26px;
    width:50px;
    background-color:var(--primary-500);
}


.input-autocomplete-round {
    height:32px !important
}
.input-autocomplete-round .p-autocomplete-input {
    font-family: 'Roboto Condensed', sans-serif;
    font-size:1.2rem;
    color: var(--gray-600);
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
    border-radius: 8px 0 0 8px;
    height: 32px !important;
    width:440px !important;
}
.input-autocomplete-round .p-autocomplete {
    outline: none;
}
.input-autocomplete-round .p-autocomplete-input-token {
    height: 28px;
}
.input-autocomplete-round .p-autocomplete-dropdown {
    background-color:var(--primary-500);
    border-radius:0 8px 8px 0;
    height: 28px !important;
    width:50px !important;
    line-height: 10px !important;
    padding: 0 10px !important;
}
.input-autocomplete-round .p-autocomplete-dropdown .p-icon {
    background-color:var(--primary-500);
}


.input-p-dropdown
.input-autocomplete .p-autocomplete-input {
    font-family: 'Roboto', sans-serif;
    color: var(--gray-700);
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
    border-radius: 0;
    height: 30px;
}
.input-autocomplete .p-autocomplete {
    border-radius: 0;
    outline: none;
    height: 30px;
}
.input-autocomplete .p-autocomplete-input-token {
    border-radius: 0;
    height: 30px;
}
.input-autocomplete .p-autocomplete-dropdown {
    background:var(--blue-500);
    height: 30px !important;
    line-height: 10px !important;
    padding: 0 10px !important;
}
.input-autocomplete .p-autocomplete-dropdown .p-icon {
    background:var(--blue-500);
}



.small-checkbox .p-checkbox-box {
    margin-left: 2px;
    margin-right: 2px;

    width: 22px;   /* Adjust the width as needed */
    height: 20px;  /* Adjust the height as needed */
}

.small-checkbox .p-checkbox-label {
    color:black;
    line-height: 26px;  /* Adjust line height to align the label */
}

.button {
    padding: 10px 20px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    color: white;
}

.corporate {
    background-color: #007bff; /* Primary Blue */
}

.private {
    background-color: #d63384; /* Pink-500 */
}

//FIRST PAGE CHART

.parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px; /* Adjust as needed */
    margin-top:-50px;
}

.funds-container {
    width: 100%;
    display: flex;
    position: relative; /* Needed for absolute positioning of markers */

}

.raised-funds, .remaining-funds {
    height: 20px;
    animation: fillAnimation 1s ease forwards;
    width: 0;
}

@keyframes fillAnimation {
    to { width: var(--final-width); }
}


// @keyframes fillAnimation {
//    from { width: 0; }
//    to { width: 100%; }
//}

.raised-funds {
    background-color: var(--pink-400);
}

.remaining-funds {
    background-color: var(--blue-700);
}

.marker {
    position: absolute;
    bottom: -10px; /* Position below the bar */
}

.line {
    width: 0.5px;
    height: 25px; /* Adjust height as needed */
    background-color: gray; /* Style as needed */
}

//.label {
//    position: absolute;
//    right: 0; /* Align the right edge of the label with the marker line */
//    padding: 5px 10px;
//    text-align: right;
//    transform: translateX(50%) !important;  /* Adjust the label position */
//    white-space: nowrap; /* Ensure the label stays in a single line */
//}



.labelLeft {
    color: var(--gray-700);
    font-family: 'Roboto Condensed Medium', sans-serif;
    font-size: 1.1rem;
    //text-align: center;
    position: absolute;
    width: 240px; /* Adjust width as needed */
    top: 25px;
    left: 0px; /* Half of the width to center the label */
    bottom: 120px; /* Adjust as needed */
    /* Add more styling for the label as needed */
}

.labelRight {
    font-family: 'Roboto Condensed Medium', sans-serif;
    font-size: 1.1rem;
    text-align: right;
    position: absolute;
    width: 350px; /* Adjust width as needed */
    top: 25px;
    left: -350px; /* Half of the width to center the label */
    bottom: 120px; /* Adjust as needed */
    /* Add more styling for the label as needed */
}

.labelCenter {
    color: var(--gray-700);
    //border:1px solid orange;
    font-family: 'Roboto Condensed Medium', sans-serif;
    font-size: 1.1rem;
    //text-align: center;
    position: absolute;
    width: 240px;
    //width: 240px;
    top: -25px;
    left: -0px; /* Half of the width to center the label */
}

/////


.details-desktop-style{
    padding-left: 10px;
    padding-right: 10px;
    margin-top:0;
}

.details-mobile-style{
    padding-left: 5px;
    padding-right: 5px;
    margin-top:50px;
}

.documents-desktop-style{
    padding-left: 10px;
    padding-right: 10px;
    margin-top:0;
}

.documents-mobile-style{
    padding-left: 5px;
    padding-right: 5px;
    margin-top:50px;
}

.footerIcon {
    font-size:small;
    color:var(--gray-700);
    margin-right:4px;
}

.my-custom-dialog  {
    background-color: white;
    padding:0px;
    margin-left:0px;
    margin-right:0px;
    bottom: 100px;
    max-width: 600px;
    width: 350px;
    min-width: 300px;
    height:350px;
}

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-disallow {
    font-family: 'Roboto Condensed', sans-serif;
    font-size:0.9rem;
    border:none;
    margin-right:0;
    background-color: var(--red-500);
    color: white;
}
.button-allow {
    font-family: 'Roboto Condensed', sans-serif;
    font-size:0.9rem;
    border:none;
    margin-right:0;
    background-color: var(--green-500);
    color: white;
}

.button-download {
    font-family: 'Roboto Condensed', sans-serif;
    font-size:0.9rem;
    border:none;
    margin-right:50px;
    margin-left:0;
    background-color: var(--blue-500);
    color: white;
}


.tdKey {
    width: 20%;
    vertical-align: top;
    text-align:left;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-size:1rem;
}

.tdVal {
    width: 80%;
    text-align:left;
    vertical-align: top;
    font-family: 'Roboto Condensed', sans-serif;
    font-size:1rem;
}

.tdCheck {
    text-align:left;
    width: 2%;
    vertical-align: top;
}
.dash_no {
    list-style-type: none;
}

//.top-bar-wrapper {
//    height: 60px;
//    width: 100%;
//    position: fixed;
//    top: 0;
//    left: 0;
//    //background-color:rgba(0, 0, 0, 0.26) !important;
//    background-color:white;
//    z-index: 1000;
//    box-shadow: none;
//}




.front-undercolor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 463px;
    background-color: white;
    z-index: 2;
    clip-path: polygon(0 0, 60% 0, 50% 100%, 0 100%);
}

.front-text-small {
    position: absolute;
    top:        7.7rem;
    font-size:  3.6rem;
    line-height: 3.5rem;
    width:      85%;
    left:       2rem;
    text-align: left;
    color:      rgb(250, 250, 250);
    font-family: 'Roboto Condensed Black', sans-serif;
    height: auto;
    z-index: 3;
    //text-shadow: 1px 1px 1px rgba(205, 205, 205, 0.49);
}

.front-subtext-small {
    position: absolute;
    top:        19.5rem;
    font-size:  2.5rem;
    line-height:2.5rem;
    width:      55%;
    left:       2rem;
    text-align: left;
    color: rgb(10, 67, 145);
    font-family: 'Roboto Condensed', sans-serif;
    height: auto;
    z-index: 3;

}


.front-text-large {
    position: absolute;
    top:        7rem;
    font-size:  6.5rem;
    line-height: 7rem;
    width:      65%;
    left:       4rem;
    text-align: left;
    color:      rgb(250, 250, 250);
    //color:      rgb(10, 67, 145);
    font-family: 'Roboto Condensed Black', sans-serif;
    height: auto;
    z-index: 3;
}


.front-subtext-large {
    position: absolute;
    top:        16rem;
    font-size:  3.5vw;
    line-height:3.5vw;
    width:      50%;
    left:       4rem;
    text-align: left;
    color: rgb(10, 67, 145);
    font-family: 'Roboto Condensed', sans-serif;
    height: auto;
    z-index: 3;
}

.front-logo {
    position: absolute;
    top:       28rem; // calc(44vh * var(--text-scale-fact));
    width:     240px;// calc(15vw * var(--text-scale-fact));
    left:      2rem;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    z-index: 3;

}



.front-logo-small {
       position: absolute;
          top:       29rem; // calc(44vh * var(--text-scale-fact));
          width:     190px;// calc(15vw * var(--text-scale-fact));
          left:      2rem;
          text-align: center;
          display: inline-block;
          cursor: pointer;
          z-index: 3;
}


.front-logo-small img {
    width: 100%; /* Ensure the image scales within the parent div */
    height: auto; /* Maintain aspect ratio */
}


.front-logo img {
    transition: filter 0.3s; /* Add transition effect to smoothly change the filter */
}

.front-logo:hover img {
    filter: brightness(70%); /* Reduce brightness to darken the image on hover */
}

.fixed-height-container {
    width: 100%;
    height: 110px;
    overflow: hidden;
}

.fixed-height-container > div {
    /* Optional: Adjust text styling */
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis; /* Show ellipsis (...) for overflow text */
    overflow: hidden; /* Hide overflow text */
}




.donate-project-button {
    border-radius: 0 0 8px 8px !important;
    font-family: 'Roboto Condensed Bold', sans-serif;
    font-weight: bold;
}

.tableCaption {
    color: var(--gray-500);
    font-family: 'Roboto Condensed Light', sans-serif;
    font-size: 2.0rem;
    font-weight:normal;
}

app-docx-processor {
    margin: 0px; /* Adjust the value as needed */
}

.custom-chip .p-chip {
    background-color: var(--primary-600);
    border-radius:4px;
    color: white;
}

.custom-chip .p-chip .pi {
    color: white;
}


.custom-chip-time .p-chip {
    background-color: var(--pink-500);
    border-radius:4px;
    width:120px;
    color: white;
}

.custom-chip-time .p-chip .pi {
    color: white;
}

.scrollable-table-container {
    max-height: 220px;
    overflow-y: auto;
}

//**********

.trustedimage-container {
    display: flex;
    justify-content: space-between;
}

.trustedimage-box {
    position: relative;
    width: 100px;
    height: 100px;
    margin:20px;
}

.trustedimage-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.initiated-transaction {
    background-color: var(--gray-50) !important; /* Light orange color; adjust as needed */
}

.pending-transaction {
    background-color: var(--orange-50) !important; /* Light orange color; adjust as needed */
}

.reserved-transaction {
    background-color: var(--green-100) !important; /* Light orange color; adjust as needed */
}

.available-transaction {
    background-color: var(--green-100) !important; /* Light orange color; adjust as needed */
}

.paidout-transaction  {
    background-color: var(--blue-100) !important; /* Light orange color; adjust as needed */
}

.rejected-transaction {
    background-color: var(--red-200) !important; /* Light orange color; adjust as needed */
}
.canceled-transaction {
    background-color: var(--pink-100) !important; /* Light orange color; adjust as needed */
}

.table-legend {
    display: flex;
    margin-top: 10px;
    margin-left: 0px;
    gap: 40px;
}

.legend-item {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 100;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    gap: 5px;
}

.legend-color {
    width: 15px;
    height: 15px;
    display: inline-block;
}

.legend-color.pending {
    background-color:var(--orange-100);
}

.legend-color.reserved {
    background-color: var(--green-100);
}



/* Adjust the size of the radio button inside p-tableRadioButton */
.p-radiobutton .p-radiobutton-box {
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    font-size: 0.7rem; /* Adjust icon size as needed */
}

.columnFlex {
    display: flex;
    justify-content: left; /* Aligns children (key and value) on the same line with space between */
    align-items: center; /* Vertically centers the children in the container */
    width: 100%; /* Ensures the container fills the width of its parent */
}

.centered-content {
    margin: auto;
    width: 500px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pi {
    cursor: pointer;
    //margin: 0;
    font-size: 1em;
    //color: white;
    color: var(--gray-800);
}



.pi:hover {
    color: darkgrey; /* Darken icon on hover to indicate interactivity */
}

.pi-arrow-right {
    color:white;
}

.custom-tab-style .p-tabview-nav a {
    font-family: 'Roboto Condensed Light', sans-serif;
    font-size: 1.3em;
    padding: 0; /* Adjust padding as needed */
    letter-spacing: -0.5px;
}

.heart-icon .fa-heart {
    color: red;
}




.progress-container {
    margin: 0 auto;
    margin-top:10px;
    width: 90%; /* Set the width of the container */
    /* Center the container horizontally */
    text-align: center; /* Align the progress bar within the container */
}

.p-progressbar {
    width: 100% !important;
    height: 12px !important; /* Adjust the height of the whole progress bar */
}

.p-progressbar .p-progressbar-value {
    height: 22px !important;
}

.p-progressbar-value .p-progressbar-label {
    color: transparent !important;
}

.p-progressbar-value {
    background-color: var(--orange-500) !important;
}

.progress-label {
    margin-top: 12px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    text-align: center; /* Center the text */
}



.progress-container .ui-progressbar .ui-progressbar-value {
    background-color: #007ad9; /* Replace with any color you want */
}

//.top-bar-button .fa-heart {
//    color: red !important;
//}



.no-scroll .p-scrollpanel-content {
    overflow: hidden !important;
}

//.video-container {
//    display: flex;         /* Enable Flexbox */
//    justify-content: center; /* Center horizontally */
//    align-items: center;   /* Optional: Center vertically if needed */
//    margin-top:20px;
//    margin-bottom:20px;
//}
//
//.video-frame {
//    border: 0px solid red;
//    width:100%;
//    border-radius: 8px;
//    background-color: white;
//}

.video-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
}

.video-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
}


.tagsLabel {
    color: white;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1rem;
    margin-top: 2px;
    margin-left: 2px;
    margin-bottom: 2px;
}
/* Reduce the size of the checkbox */
.small-checkbox .p-checkbox-box {
    transform: scale(0.75); /* Scale down the checkbox */
    transform-origin: center; /* Ensure the scaling doesn't misalign the checkbox */
    border-radius: 2px; /* Adjust if necessary to keep a square appearance */
}

.small-checkbox .p-checkbox-icon {
    font-size: 0.8em; /* Adjust the check mark size within the scaled box */
}

.articleDatetime {
    font-family: 'Roboto Condensed Light', sans-serif !important;
    color:var(--gray-800);
    margin-left: 220px;
    font-size:14px;
}

/* Styles for the chips in view mode */
/* Remove container borders and disable background changes on hover */
.view-mode-chips .p-chips  {
    border: none !important;
    background-color: transparent !important;
    pointer-events: none; /* Disables all interactions */
}

.view-mode-chips .p-chips .p-chip {
    background-color: blue !important;
    color: white !important;
    font-size: 0.8rem !important;
    border: none !important;
}

/* Hide the remove icon effectively */
.view-mode-chips .p-chips .p-chip .pi-times-circle {
    display: none !important;
}

.opop-viewtext-head {
    display: flex;         /* Enables Flexbox */
    justify-content: space-between; /* Positions children at start and end of the container */
    align-items: center;   /* Align items vertically in the center */
    margin-right: 50px;    /* Keeps your right margin as you specified */
    margin-top: 0;         /* As specified */
}

.p-sortIcon {
    color: white !important;
}

.layout-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px; /* Adjust width as necessary */
    height: 100%;
    background: #fff;
    transition: transform 0.3s ease;
    transform: translateX(-100%); /* Hide by default */
}

.layout-sidebar.sidebar-active {
    transform: translateX(0); /* Show when active */
}

.no-margins-footer {
  margin-left: -10px;
  margin-right: 0;
  width: 100%;
  border-radius:0 0 10px 10px;
  box-sizing: border-box;
}

.mail-header {
  display: grid;
  grid-template-columns: 1fr 3fr; /* Adjust the fractions as needed */
  align-items: center;
  gap: 20px;
  align-items: center;
  background-color: white;
  height: 100px;
  padding: 0 20px;
}

.mail-header-text {
    display: grid;
    grid-template-columns: 1fr;
}
.logo {
  height: 50px;
  margin-left: -20px;
}

.logoAboutUs {
  height: 30px;
  margin-left: -20px;
}

.logo-aboutus-container {
    margin-top:20px;
    margin-bottom:20px;
    display: flex;
    justify-content: center;
}

.header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left:30px;
  height: 100%;
}

.about-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10%;
    margin: 0;
    width: 100%;
    background-color: white;
}

.about-us-head,
.about-us-subhead,
.about-us-plain {
    color: var(--gray-700);
    text-align: center;
    background-color: white;
    word-wrap: break-word;
    margin: 20px 0;
}

.about-us-head {
    font-family: 'Roboto Condensed Black', sans-serif;
    font-size: 3rem;
    margin-top: 80px;
}

.about-us-subhead {
    font-family: 'Roboto Condensed Black', sans-serif;
    font-size: 1.6rem;
}

.about-us-plain {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.3rem;
}

@media (max-width: 1024px) {
    .about-us {
        padding: 0;
        margin: 0;
    }
}

.about-us-button-container {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  margin: 20px; /* Adds 20px margins around the container */
}

.about-us-centered-button {
  font-family: 'Roboto Condensed Bold', sans-serif;
  font-size: 1.5rem;
  color:white;
  display: flex;
  border-radius:8px !important;
  align-items: center; /* Vertically centers the text and icon within the button */
  margin: 20px; /* Adds 20px margin around the button */
}

.underline-link {
  text-decoration: underline;
}



/*************** NEW Parent Component Styles */
.full-screen-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5); /* Dimmed background */
    z-index: 1000; /* Ensure it's on top of other content */
}

/* Make Payment Component Styles */
//.payment-container {
//    max-width: 600px; /* Set the max-width */
//    max-height: 780px;
//    width: 100%; /* Ensure it takes full width up to the max-width */
//    height: 100%;
//    overflow-y: auto;
//    background: white;
//    z-index: 1001; /* Ensure it's on top of the overlay */
//    display: flex;
//    flex-direction: column;
//    padding: 1rem; /* Add some padding if necessary */
//    box-sizing: border-box;
//}

.payment-container {
    max-width: 600px; /* Set the max-width */
    max-height: 810px;
    margin-top:20px;
    margin-bottom:30px;
    width: 100%; /* Ensure it takes full width up to the max-width */
    height: 100%;
    overflow-y: auto;
    background: white;
    z-index: 1001; /* Ensure it's on top of the overlay */
    display: flex;
    flex-direction: column;
    padding: 1rem; /* Add some padding if necessary */
    box-sizing: border-box;
    position: relative; /* Ensure it is positioned relative to the close button */
}

.payment-content {
    flex: 1; /* Allow the content to take up available space */
}




@media (max-width: 2000px) {
    .about-us-plain .about-us-subhead  {
        margin-right: 20%;
        margin-left: 20%;
    }
}

@media (max-width: 1200px) {
    .about-us-plain .about-us-subhead {
        margin-right: 15%;
        margin-left: 15%;
    }
}

@media (max-width: 800px) {
    .about-us-plain {
        margin: 5%;
    }
}

@media (max-width: 600px) {
    .mail-header {
        grid-template-columns: 1fr;
    }
}

.layout-container {
    background: transparent;
    width:100% !important;
    height:100% !important;
    box-sizing: border-box;
    padding: 0 15rem !important;
}

.layout-content-wrapper {
    width: 100% !important;
    height:100% !important;
    max-width: 1200px!important;
    padding:0 !important;
    margin:auto !important;
}

.header-container {
    position: relative;
    width: 100%;
    min-height: 330px;
    max-height: 500px;
    overflow: hidden;
    margin-top:6rem  !important;
}

.layout-content-home,
.layout-content {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
}

.layout-content-home {
    margin-top: 1rem !important;
}

.layout-content {
    margin-top: 6rem !important;
}

.front-image {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.front-image img {
    width: calc(100% + 60px);
    height: 100%;
    display: block;
    object-fit: cover;
    margin-left: -30px;
}

.front-text, .front-subtext, .front-logo-large {
    position: absolute;
    left: 3vw;
    z-index: 2;
}

.front-text {
    top: 11vh;
    font-size: 7.3vw;
    line-height: 1.1;
    color: rgb(250, 250, 250);
    font-family: 'Roboto Condensed Black', sans-serif;
    text-align: left;
}

.front-subtext {
    top: calc(11vh + 7.3vw + 20px);
    font-size: 4.8vw;
    line-height: 1.1;
    color: rgb(250, 250, 250);
    font-family: 'Roboto Condensed Medium', sans-serif;
    text-align: left;
}

.front-logo-large {
    top: calc(11vh + 7.3vw + 5.2vw + 90px + 20px);
    width: 14vw;
    text-align: center;
    cursor: pointer;
}

.front-logo-large img {
    width: 100%;
    height: auto;
}


@media (max-width: 480px) {
    .header-container {
        height: calc(55vh);
    }
}

/* Styles for screens larger than 1300px */
@media (min-width: 1300px) {
    .layout-container {
        background: transparent;
        padding-left:10rem !important;
        padding-right:10rem !important;
    }
    .layout-content-wrapper {
        background: transparent;
    }
    .header-container {
        height: calc(55vh);
        padding: 0 !important;
        margin: 0 !important;
    }

    .front-text {
        color:white;
        top: calc(5vw + 3vh);
        font-size: 5vw;
    }
    .front-subtext {
        color:white;
        top: calc(5vw + 3vh + 5vw + 20px);
        font-size: 3vw;
    }
    .front-logo-large {
        top: calc( 5vw + 3vh + 5vw + 20px + 3vw + 80px );
        width: 10vw;
    }
}

/* Styles for screens between 992px and 1300px */
@media (max-width: 1300px) and (min-width: 992px) {
    .layout-container {
        background: transparent;
        padding-left:5rem !important;
        padding-right:5rem !important;
    }
    .layout-content-wrapper {
        background: transparent;
    }
    .header-container {
        height: calc(55vh);
        margin:0 !important;
        padding: 0 !important;
    }
    .front-text {
        color:white;
        top: calc(6vw + 3vh);
        font-size: 6vw;
    }
    .front-subtext {
        color:white;
        top: calc(6vw + 3vh + 6vw + 20px);
        font-size: 3vw;
    }
    .front-logo-large {
        top: calc( 6vw + 3vh + 6vw + 20px + 3vw + 60px );
        width: 20vw;
    }
}

/* Styles for screens smaller than 992px */
@media (max-width: 992px) and (min-width:768px) {
    .layout-container {
        background: transparent;
        padding-right:0.5rem !important;
        padding-left:0.5rem !important;
    }
    .custom-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .header-container {
        height: calc(55vh);
        margin:0 !important;
        padding: 0 !important;
    }

    .front-text {
        color:white;
        top: calc(9vw + 2vh);
        font-size: 9vw;
    }
    .front-subtext {
        color:white;
        top: calc(9vw + 2vh + 9vw + 20px);
        font-size: 5vw;
    }
    .front-logo-large {
        top: calc( 9vw + 2vh + 9vw + 20px + 5vw + 60px );
        width: 20vw;
    }
}


@media (max-width: 768px) {
    .layout-container {
        background: transparent;
        padding-left:0 !important;
        padding-right:0 !important;
    }
    .header-container {
        height: calc(55vh);
        margin:0 !important;
        padding: 0 !important;
    }

    .grid-small {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .front-text {
        color:white;
        top: calc(9vw + 8vh);
        font-size: 9vw;
    }
    .front-subtext {
        color:white;
        top: calc(9vw + 8vh + 9vw + 20px);
        font-size: 5vw;
    }
    .front-logo-large {
        top: calc(9vw + 8vh + 9vw + 20px + 5vw + 60px );
        width: 20vw;
    }
}

@media (max-width: 480px) {
    .layout-content-wrapper {
        background: transparent;
        padding-left:5px !important;
        padding-right:5px !important;
    }
    .front-text {
        color:white;
        top: calc(9vw + 9vh);
        font-size: 9vw;
    }
    .front-subtext {
        color:white;
        top: calc(9vw + 9vh + 9vw + 20px);
        font-size: 5vw;
    }
    .front-logo-large {
        top: calc(9vw + 9vh + 9vw + 20px + 5vw + 40px );
        width: 35vw;
    }
}


/* Config Sidebar Styles */
//.layout-config-button {
//    position: fixed;
//    right: 0;
//    top: 50%;
//    transform: translateY(-50%);
//    z-index: 20;
//}
//
//.layout-config-sidebar {
//    z-index: 20;
//    max-height: 500px; /* Set the maximum height for the sidebar */
//    overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */
//}
.layout-config-button {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 20;
    width: 2.5em; /* Adjust width using em units */
    height: 2.5em; /* Adjust height using em units */
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.layout-config-button i {
    font-size: 1.5em; /* Adjust icon size using em units */
    transform: scale(1); /* Ensure the icon stays within the button */
    margin-top: 0;
    color: white;
}

/* Sidebar styles */
.centered-sidebar {
    top: 50% !important; /* Center vertically */
    transform: translateY(-50%) !important; /* Adjust for actual centering */
    height: auto !important; /* Ensure the height is auto */
    max-height: 800px; /* Set maximum height */
    overflow-y: auto; /* Allow scrolling if content exceeds the max height */
}

.layout-config-sidebar {
    z-index: 20;
    max-height: 800px; /* Set the maximum height for the sidebar */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */
}

///////////////////////////////////

.topbar-menubutton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px; /* Adjust padding if needed */
    display: flex;
    align-items: center;
    justify-content: center;
}

.topbar-menubutton i {
    font-size: 2rem !important;
    color: var(--black-900) !important;
    margin-left: 1px;
}
